import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { buildLink } from '@amazeelabs/react-framework-bridge/gatsby'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { enhanceStrapiData } from '@/utils/enhanceStrapiData'
import { getSiteData } from '@/utils/siteData'
import { ArticleTypes } from '@/constants/enums'
import Layout from '@/components/layout'
import Sections from '@/components/sections'
import SEO from '@/components/seo'
import HeroInsight from '@/components/sections/HeroInsight'
import ArticleCollectionColumn from '@/components/blocks/ArticleCollectionColumn'

const Insight = ({ data = {}, pageContext }) => {
	const { t } = useTranslation()
	const { posts, insights, releaseNotes, site, downloads } = data
	const { title, sections, datetime, platforms } =
		enhanceStrapiData(data.strapiContent) || {}
	const global = {
		posts: posts ? posts.nodes : [],
		insights: insights ? insights.nodes : [],
		releaseNotes: releaseNotes ? releaseNotes.nodes : [],
		downloads: downloads ? downloads.nodes : [],
	}

	const latestInsights = data.latestInsights.nodes || []
	const pageTitle = data.strapiContent.title;

	const heroProps = {
		badgeText: t('Global.insight'),
		headline: pageTitle,
		headlineOrder: 1,
		date: datetime,
		platforms,
	}

	const latestInsightsProps = {
		headline: t('Global.latestInsights'),
		items:
			latestInsights.length > 0 &&
			latestInsights.map((insight) => ({
				headline: insight.teaserHeadline || insight.title,
				datetime: insight.datetime,
				platforms: insight.platforms,
				Link: buildLink({ href: insight.route }),
				variant: ArticleTypes.insight,
			})),
	}


	let seo = {};
	let seoTitle = (t('SEO.insightTitle', { topic: pageTitle }));
	let seoDescripton = (data.strapiContent.seo.metaDescription);
	seo.metaDescription = seoDescripton;
	seo.Link = data.strapiContent.route;
	seo.canonicalUrl = 'https://relution.io/' + data.strapiContent.route;

	return (
		<>
			<SEO
				title={seoTitle}
				seo={seo}
				site={site.nodes[0]}
				pageContext={pageContext}
			/>
			<Layout global={getSiteData(data)} pageContext={pageContext}>
				<main>
					<HeroInsight {...heroProps} />
					<div className="box pb-30 grid gap-x-15 gap-y-30 lg:grid-cols-12">
						<div className="min-w-0 lg:col-span-7">
							<Sections sections={sections} global={global} />
						</div>
						<div className="min-w-0 lg:mt-15 lg:col-start-9 lg:col-span-4">
							{latestInsightsProps &&
								latestInsightsProps.items &&
								latestInsightsProps.items.length > 0 && (
									<ArticleCollectionColumn
										className="lgmax:pt-5 lgmax:border-t lgmax:border-t-gray-500 lg:max-w-xxs lg:pl-5 lg:border-l lg:border-l-gray-500"
										{...latestInsightsProps}
									/>
								)}
						</div>
					</div>
				</main>
			</Layout>
		</>
	)
}

Insight.defaultProps = {}

Insight.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
}

export default Insight

export const query = graphql`
  query InsightQuery(
    $id: String!
    $locale: String!
    $formatString: String = "DD.MM.YYYY"
  ) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site: allStrapiSite(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Site
      }
    }
    header: allStrapiHeader(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Header
      }
    }
    footer: allStrapiFooter(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Footer
      }
    }
    strapiContent: strapiInsight(id: { eq: $id }) {
      route
      title
      seo {
        ...SharedSeo
      }
      datetime(formatString: $formatString, locale: $locale)
      platforms {
        platformId
      }
      sections {
        ... on STRAPI__COMPONENT_BLOCKS_BADGE_DIVIDER_BLOCK {
          ...BlocksBadgeDividerBlock
        }
        ... on STRAPI__COMPONENT_BLOCKS_HEADING_BLOCK {
          ...BlocksHeadingBlock
        }
        ... on STRAPI__COMPONENT_BLOCKS_HIGHLIGHT_BLOCK {
          ...BlocksHighlightBlock
        }
        ... on STRAPI__COMPONENT_BLOCKS_IMAGE_BLOCK {
          ...BlocksImageBlock
        }
        ... on STRAPI__COMPONENT_BLOCKS_TEXT_BLOCK {
          ...BlocksTextBlock
        }
        ... on STRAPI__COMPONENT_BLOCKS_VIDEO_BLOCK {
          ...BlocksVideoBlock
        }
      }
    }
    latestInsights: allStrapiInsight(
      filter: { locale: { eq: $locale }, id: { ne: $id } }
      sort: { fields: datetime, order: DESC }
      limit: 10
    ) {
      nodes {
        route
        title
        teaserHeadline
        datetime(formatString: $formatString, locale: $locale)
        releasedAt: datetime
        platforms {
          platformId
        }
      }
    }
    downloads: allStrapiDownload(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Download
      }
    }
  }
`
